
import { defineComponent } from 'vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default defineComponent({
  name: 'ScanView',
  data() {
    return {
      scan: false as boolean
    }
  },
  components: {
    StreamBarcodeReader
  },
  methods: {
    onDecode(text: string) {
      alert(`Decode text from QR code is ${text}`)
    }
  }
});

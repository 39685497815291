
import { defineComponent } from 'vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      loadScanner: true as boolean,
      scan: false as boolean,
      eyesOpen: true as boolean,
    }
  },
  components: {
    StreamBarcodeReader
  },
  async mounted() {
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    await sleep(1500);
    this.eyesOpen = false;
    await sleep(250);
    this.eyesOpen = true;
    await sleep(1750);
    this.eyesOpen = false;
    await sleep(250);
    this.eyesOpen = true;
    await sleep(300);
    this.eyesOpen = false;
    await sleep(250);
    this.eyesOpen = true;
    await sleep(1000);
    //this.loadScanner = true;
    //this.$router.push('scan')
  },
  methods: {
    clickBeak() {
      const messages = [
        '¡Ay, que te pico!',
        '¡Sorpresa!',
        'Hola, soy Lem, ¡un placer conocerte!'
      ]
      const randomMessage = messages[Math.floor(Math.random() * messages.length)];
      alert(randomMessage);
    },
    onDecode(text: string) {
      alert(`Decode text from QR code is ${text}`)
    }
  },
});

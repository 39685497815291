<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.background {
  background-color: #f6f6da;
  background-image:
    radial-gradient(at 100% 0%, #f6f6da 0px, transparent 50%),
    radial-gradient(at 0% 100%, #e7e5ba 0px, transparent 50%),
    radial-gradient(at 100% 100%, #fdfdf8 0px, transparent 50%),
    radial-gradient(at 0% 0%, #f6f6da 0px, transparent 50%);
}

body {
  background-attachment: fixed; /*edit*/
  margin: 0px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


.text {
  padding: 40px 60px;
}

.text h1 {
  font-weight: bold;
  display: block;
  margin-block-start: 1.2em;
  margin-block-end: 1.2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1.2em;
  color: #332c25;
}

.text p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 1em;
  color: #332c25;
}

.text img {
  height: 1em;
  width: 1em;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: -0.2em;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

html, body {
  overflow-y: hidden;
  overscroll-behavior-y: none;
}

body {
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.fade-enter,
.fade-leave {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ErrorView',
  data() {
    return {
      scan: false as boolean
    }
  },
  methods: {
    onDecode(text: string) {
      alert(`Decode text from QR code is ${text}`)
    }
  }
});
